import {
  Typography, Box, Link, IconButton,
} from '@mui/material';
import React from 'react';
import MailIcon from '@mui/icons-material/Mail';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function Footer() {
  return (
    <Box sx={{
      // position: { md: 'absolute' },
      bottom: { md: 0 },
      left: { md: 0 },
      right: { md: 0 },
      margin: { md: 'auto' },
      textAlign: { md: 'left' },
      width: { md: '100%' },
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: { xs: 'column', md: 'row' }, // added this line
    }}
    >
      <Typography variant="body2" sx={{ padding: '20px', marginRight: 'auto' }}>@ Fincaviz.co. All rights reserved.</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px',
        }}
        >
          <Link href="mailto:info@fincaviz.co" target="_blank" rel="noopener" sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <IconButton size="small" sx={{ mr: '5px', bgcolor: 'grey.300', borderRadius: '50%' }}>
              <MailIcon />
            </IconButton>
            <Typography variant="body2" sx={{ textDecoration: 'none' }}>support@fincaviz.co</Typography>
          </Link>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px',
        }}
        >
          <Link href="https://twitter.com/fincaviz" target="_blank" rel="noopener" sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <IconButton size="small" sx={{ mr: '5px', bgcolor: 'grey.300', borderRadius: '50%' }}>
              <TwitterIcon />
            </IconButton>
            <Typography variant="body2" sx={{ textDecoration: 'none' }}>@fincaviz</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
