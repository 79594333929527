export const queryMapping = {
  cityId: ['cityId', 'cuidadId'],
  beds: ['beds', 'camas'],
  baths: ['baths', 'banos'],
  zone: ['zone', 'estrato'],
  unit: ['unit', 'unidad'],
  type: ['type', 'tipo'],
  currency: ['currency', 'moneda'],
};

export function getSearchQuery(queryName, defaultValue = null) {
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get(queryName)
        || searchParams.get(queryMapping[queryName]?.find((q) => searchParams.has(q)));
  return value !== null ? value : defaultValue;
}
