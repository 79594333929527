import { createTheme } from '@mui/material';
import {
  grey,
} from '@mui/material/colors';

import { colors } from './constants';

export const theme = {
  main: createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#141519',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {

        },
      },
    },
    typography: {
      allVariants: {
        // color: gre[500],
      },
    },
    palette: {
      mode: 'light',
      background: {
        // default: '#31c48d'
      },
      primary: {
        main: colors.green,
      },
      secondary: {
        main: grey[500],
      },
    },
  }),
};
export default theme;
