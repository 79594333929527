// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          mainTitle: 'Data Driven',
          mainSubtitle: 'Real Estate.',
          mainDescription: 'Optimize your property search with FincaViz, the AI-powered Chrome extension tailored for fincaraiz users. Effortlessly access USD conversions, location-based price recommendations, POT data for property usage, and more. Stay ahead with FincaViz’s expanding suite of features.',
          joinBetaButton: 'Join Beta',
          joinBetaHelperText: 'Get notified of early access beta release',
          onWaitlistMessage: 'You are on the waitlist! We will notify you as soon as the beta is released!',
          internalErrorMessage: 'Oops! Something went wrong. We are working on it. Please check back in later.',
          bridgingTheGapChip: 'Bridging the gap',
          lackOfInfoTitle: 'There\'s a lack of information in Colombian Real estate.',
          lackOfInfoDescription: 'While the Colombian real estate market holds significant potential, there\'s a notable gap in accessible, comprehensive data for investors and buyers. Fincaviz gives you an edge over the market; offering a competitive advantage by bridging this information gap with its comprehensive data and analytics.',
          tryFreeTitle: 'Try it for free today',
          tryNowButton: 'Try Now',
          downloadChromeButton: 'Download',
          downloadSafariButton: 'Download',
          blogButton: 'Blog',
          calculatorButton: 'Calculator',
          extensionButton: 'Extension',
          detailBlocks: {
            browseWithConfidence: 'Browse with confidence',
            letUsWorryAboutNumbers: 'Let us worry about the numbers',
            goodOrBadDealIndicator: 'Good or Bad Deal Indicator',
            getThePriceForSqFt: 'Get the price for sqft',
            alreadyVisitedIndicators: 'Already visited indicators',
            moreThanJustCalculator: 'More than just a calculator',
            chooseTheFormatBestForYou: 'Choose the format best for you',
            selectYourLanguage: 'Select your language',
            changeYourCurrency: 'Change your currency',
            compareWithWithoutAdminFees: 'Compare between with and without admin fees',
          },
          featureBlock: {
            $ConversionTitle: '$ Conversion',
            $ConversionDescription: 'Seamlessly translate COP to USD and reverse using our tool, simplifying the process of financial evaluation for property investments and offering a quick reference for international buyers and investors.',
            PriceEstimationTitle: 'Price Estimation',
            PriceEstimationDescription: 'Receive an accurate valuation for any property, tailored by its geographical position and unique characteristics, ensuring you have a reliable estimate for informed real estate decision-making.',
            RealtorReviewsTitle: 'Realtor Reviews',
            RealtorReviewsDescription: 'Access a comprehensive overview of realtor evaluations and ratings for any property, providing insight into the experiences and satisfaction of previous clients to guide your selection process.',
          },
          calculatorPage: {
            estimateTitle: 'Estimate',
            currencyLabel: 'Currency',
            forSale: 'For Sale',
            forRent: 'For Rent',
            standard: 'Standard',
            metric: 'Metric',
            beds: 'Beds',
            baths: 'Baths',
            estrato: 'Estrato',
            insufficientDataAlert: 'Data is insufficient to get price estimate. Please adjust the parameters.',
            failureAlert: 'Failed to get price estimate. Please try again later.',
            noComparableProperties: 'No comparable properties',
            basedOnComparableProperties: 'Based on {{count}} comparable properties',
            squareFeet: 'Square Feet',
            metersSquared: 'm2',
            city: 'City',
          },
          navbar: {
            calculator: 'Calculator',
            extension: 'Extension',
            blog: 'Blog',
            english: 'English',
            spanish: 'Spanish',
            menu: 'Menu',
          },
        },
      },
      es: {
        translation: {
          mainTitle: 'Datos que impulsan el',
          mainSubtitle: 'Sector Inmobiliario.',
          mainDescription: 'Optimiza tu búsqueda de propiedades con FincaViz, la extensión para Chrome potenciada por IA y diseñada para los usuarios de fincaraiz. Accede sin esfuerzo a conversiones de USD, recomendaciones de precios basadas en la ubicación, datos de POT para usos de propiedad y más. Mantente a la vanguardia con la creciente suite de características de FincaViz.',
          joinBetaButton: 'Únete a la Beta',
          joinBetaHelperText: 'Recibe notificación del lanzamiento de la beta con acceso anticipado',
          onWaitlistMessage: '¡Estás en la lista de espera! Te notificaremos tan pronto como se lance la beta.',
          internalErrorMessage: '¡Ups! Algo salió mal. Estamos trabajando en ello. Por favor, vuelve a intentarlo más tarde.',
          bridgingTheGapChip: 'Cerrando la brecha',
          lackOfInfoTitle: 'Hay una falta de información en el sector inmobiliario colombiano.',
          lackOfInfoDescription: 'Aunque el mercado inmobiliario colombiano tiene un potencial significativo, hay una notable brecha en los datos accesibles y completos para inversores y compradores. Fincaviz te da una ventaja sobre el mercado; ofreciendo una ventaja competitiva al cerrar esta brecha de información con sus datos y análisis integrales.',
          tryFreeTitle: 'Pruébalo gratis hoy',
          tryNowButton: 'Prueba Ahora',
          downloadChromeButton: 'Descargar',
          downloadSafariButton: 'Descargar',
          blogButton: 'Blog',
          calculatorButton: 'Calculadora',
          extensionButton: 'Extensión',
          detailBlocks: {
            browseWithConfidence: 'Navega con confianza',
            letUsWorryAboutNumbers: 'Nosotros nos preocupamos por los números',
            goodOrBadDealIndicator: 'Indicador de buena o mala oferta',
            getThePriceForSqFt: 'Obtén el precio por pie cuadrado',
            alreadyVisitedIndicators: 'Indicadores de ya visitado',
            moreThanJustCalculator: 'Más que solo un calculador',
            chooseTheFormatBestForYou: 'Elige el formato que mejor te convenga',
            selectYourLanguage: 'Selecciona tu idioma',
            changeYourCurrency: 'Cambia tu moneda',
            compareWithWithoutAdminFees: 'Compara con y sin gastos administrativos',
          },
          featureBlock: {
            $ConversionTitle: 'Conversión de Moneda',
            $ConversionDescription: 'Traduce sin problemas de COP a USD y viceversa con nuestra herramienta, simplificando el proceso de evaluación financiera para inversiones inmobiliarias y ofreciendo una referencia rápida para compradores e inversores internacionales.',
            PriceEstimationTitle: 'Estimación de Precios',
            PriceEstimationDescription: 'Recibe una valoración precisa para cualquier propiedad, personalizada por su posición geográfica y características únicas, asegurando tener una estimación confiable para la toma de decisiones informadas en bienes raíces.',
            RealtorReviewsTitle: 'Opiniones de Agentes Inmobiliarios',
            RealtorReviewsDescription: 'Accede a una visión general completa de las evaluaciones y calificaciones de agentes inmobiliarios para cualquier propiedad, proporcionando una visión de las experiencias y satisfacción de clientes anteriores para guiar tu proceso de selección.',
          },
          calculatorPage: {
            estimateTitle: 'Estimar',
            currencyLabel: 'Moneda',
            forSale: 'En Venta',
            forRent: 'En Alquiler',
            standard: 'Estándar',
            metric: 'Métrico',
            beds: 'Habitaciones',
            baths: 'Baños',
            estrato: 'Estrato',
            city: 'Ciudad',
            insufficientDataAlert: 'Datos insuficientes para obtener una estimación de precios. Por favor ajusta los parámetros.',
            failureAlert: 'Fallo al obtener una estimación de precios. Por favor intente de nuevo más tarde.',
            noComparableProperties: 'No hay propiedades comparables',
            basedOnComparableProperties: 'Basado en {{count}} propiedades comparables',
            squareFeet: 'Pies Cuadrados',
            metersSquared: 'm2',
          },
          navbar: {
            calculator: 'Calculadora',
            extension: 'Extensión',
            blog: 'Blog',
            english: 'Inglés',
            spanish: 'Español',
            menu: 'Menú',
          },
        },
      },
      // ... any other languages
    },
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
