/* eslint-disable */

import React from 'react';
import { Typography, Container, Paper } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography paragraph>
          Welcome to Fincaviz Chrome Extension ("the Extension"). We are committed to
          protecting your privacy and handling your personal information with care.
          This Privacy Policy explains how we collect, use, disclose, and safeguard your
          personal information when you use our Chrome extension.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data Collection
        </Typography>
        <Typography paragraph>
          When you use the Fincaviz Chrome Extension, we may collect the following types
          of personal information:
        </Typography>
        <ul>
          <li>Name: We collect your name to personalize your experience.</li>
          <li>Address: We may collect your address for specific functionalities or services.</li>
          <li>Email Address: Your email address may be collected for communication purposes.</li>
          <li>Age: We collect your age to ensure that you meet age-related requirements.</li>
          <li>Identification Number: We may collect an identification number for specific purposes.</li>
        </ul>
        <Typography paragraph>
          We collect this information for the purposes of providing personalized experiences,
          communicating with you, verifying your age, and other specific purposes that we may
          disclose at the time of data collection.
        </Typography>
        {/* Add any additional content about data handling here */}
      </Paper>
    </Container>
  );
}

export default PrivacyPolicy;
