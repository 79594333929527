import React from 'react';
import {
  Typography, Box, Card, CardMedia, Fade,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { colors } from '../../constants';

export default function DetailBlocks() {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const inView2 = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <Box>
      <Fade in={inView} timeout={1000}>
        <Box ref={ref} display="flex" flexDirection={{ xs: 'column', md: 'row' }} flex="1" sx={{ mb: 6, mt: 6 }}>
          <Box
            flex="1"
            sx={{
              mb: { md: 6 },
              mt: { md: 6 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 'bold', textAlign: 'left', fontFamily: 'Architects Daughter', color: colors.green,
                }}
              >
                {t('detailBlocks.browseWithConfidence')}
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                {t('detailBlocks.letUsWorryAboutNumbers')}
              </Typography>
              <Box>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                  {t('detailBlocks.goodOrBadDealIndicator')}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                  {t('detailBlocks.getThePriceForSqFt')}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                  {t('detailBlocks.alreadyVisitedIndicators')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box flex="1" sx={{ mb: { md: 6 }, mt: { md: 6 } }}>
            <Card sx={{ width: '100%' }}>
              <CardMedia
                component="img"
                height="140"
                image="/block1.png"
                alt="Block 1"
              />
            </Card>
          </Box>
        </Box>
      </Fade>
      <Fade in={inView2.inView} timeout={1000}>
        <Box ref={inView2.ref} display="flex" flexDirection={{ xs: 'column-reverse', md: 'row' }} flex="1" sx={{ mb: 6, mt: 6 }}>
          <Box flex="1">
            <Card sx={{ width: '80%' }}>
              <CardMedia
                component="img"
                height="140"
                image="/block2.png"
                alt="Block 2"
              />
            </Card>
          </Box>
          <Box
            flex="1"
            sx={{
              mb: { md: 6 },
              mt: { md: 6 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 'bold', textAlign: 'left', fontFamily: 'Architects Daughter', color: colors.green,
                }}
              >
                {t('detailBlocks.moreThanJustCalculator')}
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                {t('detailBlocks.chooseTheFormatBestForYou')}
              </Typography>
              <Box>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                  {t('detailBlocks.selectYourLanguage')}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                  {t('detailBlocks.changeYourCurrency')}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                  {t('detailBlocks.compareWithWithoutAdminFees')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}
