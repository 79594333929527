import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Box, TextField, Slider,
  Typography, Select, MenuItem,
  InputLabel, FormControl, Switch,
  Snackbar, Alert, Grid,
} from '@mui/material';
import { debounce } from 'lodash';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Bathroom, Bed } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Footer from '../../partials/Footer';
import { getSearchQuery } from '../../utils/query';
import { CalculatorContext } from '../../context/Calculator';
import FilterableSelect from '../../partials/FilterableSelect';
import { numberWithCommas, numberWithPeriods } from '../../utils';
import Navbar from '../../partials/Navbar';
import ScrollTrack from '../../partials/ScrollTrack';

function updateUrl(formData) {
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(formData).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
}

export default function CalculatorPage() {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState(getSearchQuery('currency') === 'USD' ? 'USD' : 'COP');
  const [unit, setUnit] = useState(getSearchQuery('unit') === 'sqft' ? 'sqft' : 'm2');

  const [formData, setFormData] = useState({
    cityId: getSearchQuery('cityId', 1) ? parseInt(getSearchQuery('cityId', 1), 10) : 1,
    areaPrivate: getSearchQuery('areaPrivate', 50) ? parseInt(getSearchQuery('areaPrivate', 50), 10) : 50,
    beds: getSearchQuery('beds', 1) ? parseInt(getSearchQuery('beds', 1), 10) : 1,
    baths: getSearchQuery('baths', 1) ? parseInt(getSearchQuery('baths', 1), 10) : 1,
    zone: getSearchQuery('zone', 5) ? parseInt(getSearchQuery('zone', 5), 10) : 5,
    type: getSearchQuery('type', 'FOR_RENT') ? getSearchQuery('type', 'FOR_RENT') : 'FOR_RENT',
  });

  const [calculatorState, calculatorActions] = useContext(CalculatorContext);

  const cities = calculatorState.cities.map((e) => ({
    ...e,
    disabled: !e.enabled,
    value: e.id,
    label: `${e.department} - ${e.name}`,
  }));
  const debouncedFetch = debounce((searchText) => {
    calculatorActions.citySearch(searchText);
  }, 300);

  const debouncedCalculate = useCallback(debounce((data, unitPassed) => {
    let { areaPrivate } = data;
    const unitSelected = unitPassed || unit;

    if (unitSelected === 'sqft') {
      areaPrivate = parseInt((areaPrivate * 0.092903).toFixed(0), 10);
    }
    calculatorActions.calculate({ ...data, areaPrivate });
  }, 300), [calculatorActions, unit]);

  const handleChange = (event) => {
    const newFormData = {
      ...formData,
      [event.target.name]: event.target.value,

    };
    updateUrl(newFormData);
    setFormData(newFormData);
    debouncedCalculate(newFormData);
  };
  const handleChangeNumber = (event) => {
    const value = parseInt(event.target.value, 10) || 0;
    const newFormData = {
      ...formData,
      [event.target.name]: value,

    };
    updateUrl(newFormData);
    setFormData(newFormData);
    debouncedCalculate(newFormData);
  };

  const handleSliderChange = (event, value) => {
    const newFormData = {
      ...formData,
      [event.target.name]: value,

    };
    updateUrl(newFormData);
    setFormData(newFormData);
    debouncedCalculate(newFormData);
  };

  const handleUnitChange = (event) => {
    const newUnit = event.target.checked ? 'm2' : 'sqft';
    setUnit(newUnit);
    updateUrl({ ...formData, unit: newUnit });
    debouncedCalculate(formData, newUnit);
  };

  useEffect(() => { debouncedCalculate(formData); }, []);

  return (
    <>
      <ScrollTrack page="calculator" />
      <Navbar loading={calculatorState.fetching} showCalculatorButton={false} />
      <Box sx={{ width: '70%', margin: 'auto', maxWidth: '1200px' }}>
        <Snackbar
          sx={{
            margin: 'auto',
          }}
          open={calculatorState.error}
          autoHideDuration={6000}
          onClose={calculatorActions.clearError}
        >
          <Alert onClose={calculatorActions.clearError} severity="error" sx={{ width: '100%' }}>
            {calculatorState.error?.match(/\[API_ERROR\]: /g)
              ? t('calculatorPage.insufficientDataAlert')
              : t('calculatorPage.failureAlert')}
          </Alert>
        </Snackbar>

        <Box sx={{ mt: 10 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t('calculatorPage.estimateTitle')}
          </Typography>
          {currency === 'USD' && (
          <Typography variant="h4">
            $
            {numberWithCommas((calculatorState.priceEstimate.priceLow / 4100).toFixed(2))}
            {' '}
            - $
            {numberWithCommas((calculatorState.priceEstimate.priceHigh / 4100).toFixed(2))}
          </Typography>
          )}
          {currency === 'COP' && (
          <Typography variant="h4">
            $
            {numberWithPeriods(calculatorState.priceEstimate.priceLow)}
            {' '}
            - $
            {numberWithPeriods(calculatorState.priceEstimate.priceHigh)}
          </Typography>
          )}
          {calculatorState.priceEstimate.comps === 0 && (
          <Typography>{t('calculatorPage.noComparableProperties')}</Typography>
          )}
          {calculatorState.priceEstimate.comps !== 0 && (
          <Typography variant="subtitle1">
            {t('calculatorPage.basedOnComparableProperties', { count: calculatorState.priceEstimate.comps || 0 })}
          </Typography>
          )}

        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <Box sx={{ width: '100%' }}>
            <form>
              <Grid container>
                <Grid xs={12} md={6}>
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel id="currency-label">{t('calculatorPage.currencyLabel')}</InputLabel>
                    <Select
                      labelId="currency-label"
                      id="currency"
                      label={t('calculatorPage.currencyLabel')}
                      value={currency}
                      onChange={(e) => {
                        setCurrency(e.target.value);
                        updateUrl({ currency: e.target.value });
                      }}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="COP">COP</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6}>
                  <FormControl fullWidth sx={{ mb: 8 }}>
                    <Box sx={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '9px',
                    }}
                    >
                      <Typography>{t('calculatorPage.forSale')}</Typography>
                      <Switch
                        checked={formData.type === 'FOR_RENT'}
                        onChange={() => {
                          const newFormData = {
                            ...formData,
                            type: formData.type === 'FOR_SALE'
                              ? 'FOR_RENT'
                              : 'FOR_SALE',
                          };
                          setFormData(newFormData);
                          debouncedCalculate(newFormData);
                        }}
                        name="unit"
                        color="primary"
                      />
                      <Typography>{t('calculatorPage.forRent')}</Typography>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                <FilterableSelect
                  label={t('calculatorPage.city')}
                  onSearchChange={debouncedFetch}
                  items={cities}
                  value={formData.cityId}
                  onChange={handleChange}
                  name="cityId"
                  sx={{ mb: 3 }}
                />
                <Grid container>
                  <Grid xs={12} md={6}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <TextField
                        label={unit === 'sqft' ? t('calculatorPage.squareFeet') : t('calculatorPage.metersSquared')}
                        variant="outlined"
                        fullWidth
                        name="areaPrivate"
                        type="number"
                        inputProps={{ min: 1, max: 500 }}
                        value={formData.areaPrivate}
                        onChange={handleChangeNumber}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <FormControl fullWidth sx={{ mb: 8 }}>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '9px',
                      }}
                      >
                        <Typography>{t('calculatorPage.standard')}</Typography>
                        <Switch
                          checked={unit === 'm2'}
                          onChange={handleUnitChange}
                          name="unit"
                          color="primary"
                        />
                        <Typography>{t('calculatorPage.metric')}</Typography>
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <Typography id="beds-slider" gutterBottom>
                    <Bed sx={{ mr: 2 }} />
                    {t('calculatorPage.beds')}
                    {' '}
                    -
                    {formData.beds}
                  </Typography>
                  <Slider
                    name="beds"
                    value={formData.beds}
                    onChange={handleSliderChange}
                    min={1}
                    max={6}
                    step={1}
                    aria-labelledby="beds-slider"
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <Typography id="baths-slider" gutterBottom>
                    <Bathroom sx={{ mr: 2 }} />
                    {t('calculatorPage.baths')}
                    {' '}
                    -
                    {formData.baths}
                  </Typography>
                  <Slider
                    name="baths"
                    value={formData.baths}
                    onChange={handleSliderChange}
                    min={1}
                    max={6}
                    step={1}
                    aria-labelledby="baths-slider"
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <Typography id="estrato-slider" gutterBottom>
                    <LocationOnIcon sx={{ mr: 2 }} />
                    {t('calculatorPage.estrato')}
                    {' '}
                    -
                    {formData.zone}
                  </Typography>
                  <Slider
                    name="zone"
                    value={formData.zone}
                    onChange={handleSliderChange}
                    min={1}
                    max={6}
                    step={1}
                    aria-labelledby="estrato-slider"
                  />
                </FormControl>
              </Box>
            </form>
          </Box>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
