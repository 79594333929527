import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useInView } from 'react-intersection-observer';
import { colors } from '../../constants';

const features = [
  {
    icon: <AttachMoneyIcon />,
    title: '$ Conversion',
    description: 'Seamlessly translate COP to USD and reverse using our tool, simplifying the process of financial evaluation for property investments and offering a quick reference for international buyers and investors.        ',
  },
  {
    icon: <CollectionsBookmarkIcon />,
    title: 'Price Estimation',
    description: 'Receive an accurate valuation for any property, tailored by its geographical position and unique characteristics, ensuring you have a reliable estimate for informed real estate decision-making.',
  },
  {
    icon: <RateReviewIcon />,
    title: 'Realtor Reviews',
    description: 'Access a comprehensive overview of realtor evaluations and ratings for any property, providing insight into the experiences and satisfaction of previous clients to guide your selection process.',
  },

];

export default function FeatureBlock() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.2, // adjust this value to change when the fade-in effect occurs
    triggerOnce: true, // leave true to animate only once on load
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        margin: 'auto',
        mt: 2,
        mb: 6,
      }}
    >
      {features.map((feature) => (
        <Box
          key={feature.title}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { md: '30%', xs: '100%' },
            opacity: inView ? 1 : 0, // set the opacity based on the inView value
            transition: 'opacity 0.5s ease-in-out', // add a transition effect to the opacity change
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '3em',
            height: '3em',
            background: colors.green,
            borderRadius: '50%',
            color: 'white',
            marginBottom: '20px',
            padding: '30px',
            mt: 3,
          }}
          >
            {feature.icon}
          </Box>
          <Typography sx={{ paddingBottom: '20px', fontWeight: 'bold' }} variant="h5">
            {t(`featureBlock.${feature.title.replace(/\s+/g, '')}Title`)}
          </Typography>
          <Typography sx={{ lineHeight: 1.5 }} variant="body1">
            {t(`featureBlock.${feature.title.replace(/\s+/g, '')}Description`)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
