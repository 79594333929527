// @ts-nocheck
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography, Grid, FormControl, FormHelperText,
  Button, Box, Divider, Chip,
} from '@mui/material';

import { useTracking } from 'react-tracking';
import DetailBlocks from '../../partials/DetailBlocks';
import FeatureBlock from '../../partials/Features';
import { colors } from '../../constants';
import Footer from '../../partials/Footer';
import Navbar from '../../partials/Navbar';
import ScrollTrack from '../../partials/ScrollTrack';

function Main() {
  const [betaClick, setBetaClick] = useState(false);
  const [emailSent] = useState(false);
  const [internalError] = useState(false);
  const { t } = useTranslation();
  const { trackEvent } = useTracking({ page: 'home' });

  return (
    <>
      <ScrollTrack page="home" />
      <Navbar showDownloadButton={false} />
      <Box sx={{
        width: { xs: '90%', md: '70%' }, maxWidth: '1200px', margin: 'auto', mt: 10,
      }}
      >
        <Grid container>
          <Grid container xs={12}>
            <Grid
              xs={12}
              md={6}
              container
              sx={{
                textAlign: 'left', display: 'flex', alignItems: 'baseline', height: 'auto',
              }}
            >
              <Grid xs={12} item>
                <Typography variant="h1" sx={{ fontSize: { xs: '60px', md: '80px' } }}>
                  {t('mainTitle')}
                  <span style={{ color: colors.green }}>
                    {' '}
                    {t('mainSubtitle')}
                  </span>
                </Typography>
                <Typography>
                  {t('mainDescription')}
                </Typography>

                {betaClick && !emailSent && !internalError && (
                <Box
                  sx={{
                    display: { xs: 'block', md: 'flex' },
                    alignItems: 'flex-start',
                    '& > :not(style)': { m: 1 },
                    marginTop: '60px',
                    textAlign: 'center',
                    height: '100px',
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      window.open('https://blog.fincaviz.co/try-today', '_blank');
                      // @ts-ignore
                      trackEvent({ action: 'click', label: 'try-today' });
                    }}
                    sx={{
                      width: { xs: '100%', md: 'inherit' },
                      marginLeft: { xs: '0px !important', md: '8px !important' },
                      marginRight: { xs: '0px !important', md: '8px !important' },
                    }}
                  >
                    {t('downloadChromeButton')}
                    <img style={{ width: '30px', paddingLeft: '2px' }} src="/chrome.png" alt="chrome" />
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    disabled
                    sx={{
                      width: { xs: '100%', md: 'inherit' },
                      marginLeft: { xs: '0px !important', md: '8px !important' },
                      marginRight: { xs: '0px !important', md: '8px !important' },
                    }}
                  >
                    {t('downloadSafariButton')}
                    <img style={{ width: '30px', paddingLeft: '2px' }} src="/safari.png" alt="safari" />
                  </Button>
                </Box>

                )}
                {!betaClick && !emailSent && !internalError && (
                <FormControl sx={{
                  marginTop: '60px', textAlign: 'center', height: '100px', width: { xs: '100%', md: 'inherit' },
                }}
                >
                  <Button
                    onClick={() => {
                      setBetaClick(true);
                      trackEvent({ action: 'click', label: 'join-beta' });
                    }}
                    variant="contained"
                    size="large"
                  >
                    {t('joinBetaButton')}
                  </Button>
                  <FormHelperText id="my-helper-text">{t('joinBetaHelperText')}</FormHelperText>
                </FormControl>
                )}

                {emailSent && (
                <Box sx={{ height: '100px' }}>
                  <Typography variant="h6" sx={{ marginTop: '60px', width: { xs: '100%', md: 'inherit' } }}>
                    {t('onWaitlistMessage')}
                  </Typography>

                </Box>
                )}

                {internalError && (
                <Box sx={{ height: '100px' }}>
                  <Typography variant="h6" sx={{ marginTop: '60px', width: { xs: '100%', md: 'inherit' } }}>
                    {t('internalErrorMessage')}
                  </Typography>
                </Box>
                )}

              </Grid>
            </Grid>
            <Grid sx={{ paddingTop: '20px', height: 'auto' }} xs={12} md={6} item>
              <img src="flat1.png" alt="Graphic" />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 6, mb: 3 }}>
          <Chip label={t('bridgingTheGapChip')} color="primary" sx={{ mb: 2, color: 'white', fontSize: '1em' }} />
          <Typography sx={{ mb: 3 }} variant="h3">{t('lackOfInfoTitle')}</Typography>
          <Typography>
            {t('lackOfInfoDescription')}
          </Typography>
        </Box>
        <FeatureBlock />
        <Divider />
        <DetailBlocks />
        <Divider sx={{ mt: { x: 1, md: 8 }, mb: { x: 1, md: 6 } }} />
        <Box sx={{ mt: { xs: 1, md: 6 }, mb: { xs: 1, md: 6 } }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {t('tryFreeTitle')}
          </Typography>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              trackEvent({ action: 'click', label: 'try-now-bottom-button' });
              setBetaClick(true);
            }}
            size="large"
            variant="contained"
          >
            {t('tryNowButton')}
          </Button>
        </Box>

        <Footer />
      </Box>
    </>
  );
}

export default Main;
