import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, {
  createContext, useReducer, useEffect, useMemo,
} from 'react';
import { ApiClient } from '../Api';

export const CalculatorContext = createContext();

const initialState = {
  result: 0,
  cities: [],
  initialized: false,
  fetching: 0,
  priceEstimate: { priceLow: 0, priceHigh: 0 },
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return { ...state, result: action.payload.a + action.payload.b };
    case 'SET_CITIES':
      return { ...state, cities: action.payload };
    case 'SET_FETCHING':
      return {
        ...state,
        fetching: Math.max(action.payload
          ? state.fetching + 1
          : state.fetching - 1, 0),
      };
    case 'SET_PRICE_ESTIMATE':
      return { ...state, priceEstimate: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

function CalculatorProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(() => ({
    async citySearch(name = null) {
      dispatch({ type: 'SET_FETCHING', payload: true });
      const response = await ApiClient.query({
        query: gql`
                            query($query: CityQuery) {
                                    city(query: $query) {
                                            department,
                                            name,
                                            enabled,
                                            id,
                                    }
                            }
                            `,
        variables: {
          query: {
            name,
            orderBy: {
              field: 'enabled',
              desc: true,
            },
          },
        },
      });

      dispatch({ type: 'SET_CITIES', payload: response.data.city });
      dispatch({ type: 'SET_FETCHING', payload: false });
    },

    async calculate(formData) {
      dispatch({ type: 'SET_FETCHING', payload: true });
      const response = await ApiClient.query({
        query: gql`
                    query($attributes: ListingAttributesInput!) {
                        getEstimate(attributes: $attributes) {
                            priceHigh,
                            priceLow,
                            comps
                        }
                    }
                    `,
        variables: {
          attributes: {
            ...formData,
          },
        },
      }).catch((e) => {
        dispatch({ type: 'SET_ERROR', payload: e.message });
        dispatch({ type: 'SET_PRICE_ESTIMATE', payload: { priceLow: 0, priceHigh: 0 } });
      });

      if (response?.data?.getEstimate.priceHigh === '0'
                && response?.data?.getEstimate.priceLow === '0') {
        dispatch({
          type: 'SET_ERROR',
          payload: '[API_ERROR]: Bad Estimate',
        });
      }
      if (response?.data) {
        dispatch({ type: 'SET_PRICE_ESTIMATE', payload: response.data.getEstimate });
      }

      dispatch({ type: 'SET_FETCHING', payload: false });
    },

    clearError() {
      dispatch({ type: 'SET_ERROR', payload: null });
    },

  }), []);

  useEffect(() => {
    actions.citySearch();
  }, []);

  return (
    <CalculatorContext.Provider value={useMemo(() => [state, actions], [state])}>
      {children}
    </CalculatorContext.Provider>
  );
}

CalculatorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CalculatorProvider;
